<template>
    <v-row>
        <v-col cols="12" lg="3">
            <base-card>
                <v-list>
                    <!-- <v-subheader>FAQ v3</v-subheader> -->
                    <v-list-item-group v-model="item" color="primary">
                        <v-list-item v-for="(item, i) in items" :key="i">
                            <v-list-item-icon>
                                <v-icon v-text="item.icon" />
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text" />
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </base-card>
        </v-col>
        <v-col cols="12" lg="8">
            <div class="mb-4"  v-if="item == 2">
                <v-expansion-panels flat v-model="activeLicencePanel">
                    <v-expansion-panel
                        v-for="(item, i) in 1"
                        :key="i"
                        class="mb-3 shadow-none hg-faq-one-panel py-2"
                    >
                        <v-expansion-panel-header>
                            License
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p><strong>Effective as of 1st April 2024.</strong></p>
                            <p>Welcome to Know The Game.</p>
                            <p>By accessing and using the website, database, and graph tools provided by Know The Game ("we," "us," "our"), you agree to the following terms and conditions. Please read them carefully.</p>

                            <h3>1. Grant of License</h3>
                            <p>
                                We grant you a non-exclusive, non-transferable, limited license to access and use our website, database, and graph tools for personal, non-commercial purposes. This license is subject to the terms and conditions set forth in this agreement.
                            </p>

                            <h3>2. Use of Content</h3>
                            <p><strong>Personal Use:</strong> You are free to use our site, database, and graph tools to create graphs for personal use.</p>
                            <p><strong>Media Use:</strong> If the graphs or any content derived from our website are used in media (including but not limited to online publications, print media, and broadcasts), an acknowledgment attributing "Know The Game" must be made publicly in the content or credits.</p>

                            <h3>3. Ownership of Data</h3>
                            <p>
                                The data represented in the graphs and any other content on the website is the exclusive property of Know The Game. The data, in its raw or graphed form, may not be copied, cloned, duplicated, downloaded, scraped, or otherwise extracted from the site, in whole or in part, under any circumstances without express written permission from Know The Game.
                            </p>

                            <h3>4. Restrictions</h3>
                            <p>You agree not to:</p>
                            <ul>
                                <li>Modify, translate, adapt, merge, make derivative works of, disassemble, decompile, reverse compile or reverse engineer any part of the website or any software provided as part of the website, except to the extent the foregoing restrictions are expressly prohibited by applicable law.</li>
                                <li>Use any robot, spider, other automatic devices, or manual process to monitor or copy any content from the website.</li>
                                <li>Resell, sublicense, rent, lease, or exploit for any commercial purposes any portion of the website, use of the website, or access to the website.</li>
                            </ul>

                            <h3>5. Modification of Terms</h3>
                            <p>
                                We reserve the right, at our discretion, to change, modify, add, or remove portions of these terms at any time by posting the amended terms on the Know The Game website. Please check these terms periodically for changes.
                            </p>

                            <h3>6. Termination</h3>
                            <p>
                                This license is effective until terminated by either party. Your rights under this license will terminate automatically without notice from us if you fail to comply with any term(s) of this license. Upon termination of the license, you shall cease all use of the website, database, and graph tools, and destroy all copies, full or partial, of the content.
                            </p>

                            <h3>7. Disclaimer of Warranties and Limitation of Liability</h3>
                            <p>
                                THE WEBSITE, DATABASE, AND GRAPH TOOLS ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. KNOW THE GAME DISCLAIMS ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
                            </p>
                            <p>
                                IN NO EVENT SHALL KNOW THE GAME BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE WEBSITE, DATABASE, AND GRAPH TOOLS.
                            </p>

                            <h3>8. General</h3>
                            <p>
                                This license constitutes the entire agreement between you and Know The Game regarding your use of the website, superseding any prior agreements between you and Know The Game regarding your use of the website.
                            </p>

                            <h3>Contact Us</h3>
                            <p>
                                If you have any questions about this license, please contact us.
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <div class="mb-4" v-if="item == 0">
                <v-expansion-panels flat>
                    <v-expansion-panel
                        v-for="(item, i) in cookiesSections"
                        :key="i"
                        class="mb-3 shadow-none hg-faq-one-panel py-2"
                    >
                        <v-expansion-panel-header
                            >{{ item.title }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            {{  item.value }}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <div class="mb-4"  v-if="item == 1">
                <v-expansion-panels flat v-model="activeLegalPanel">
                    <v-expansion-panel
                        v-for="(item, i) in 1"
                        :key="i"
                        class="mb-3 shadow-none hg-faq-one-panel py-2"
                    >
                        <v-expansion-panel-header>
                            Privacy Policy
                        </v-expansion-panel-header>
                        <v-expansion-panel-content> 
                            <p>In this Privacy Policy, terms such as "we", "us," "our" or similar expressions shall mean those operating the Know the Game competition.</p>
                            <p>We take your privacy very seriously and are committed to protecting your personal data (meaning any information about you from which you can be identified). This Privacy Policy explains what personal data we collect when you access knowthegame.com, (regardless of where you visit it from) and/or when you otherwise interact or communicate with us. It also explains how we may use this data, and what rights you have in relation to such data.</p>
                            <p>We keep our privacy practices under review and may change this Privacy Policy from time to time by posting changes on the Services or otherwise notifying them to you. This version of the Policy is dated 27 February 2024.</p>
                            <p>This Privacy Policy is provided in a layered format so you can click through the sections below. Alternatively, you can download a PDF version of the Privacy Policy here: <a href="link">link</a></p>
                            <p>Please read this Privacy Policy carefully:</p>
                            <ul>
                                <li>1. BACKGROUND</li>
                                <li>2. PERSONAL DATA WE COLLECT ABOUT YOU</li>
                                <li>3. HOW WE COLLECT YOUR PERSONAL DATA</li>
                                <li>4. HOW AND WHY WE USE YOUR PERSONAL DATA</li>
                                <li>5. DISCLOSURE OF YOUR PERSONAL DATA</li>
                                <li>6. SECURITY OF YOUR PERSONAL DATA</li>
                                <li>7. LINKS TO OTHER WEBSITES</li>
                                <li>8. DATA RETENTION</li>
                                <li>9. YOUR RIGHTS</li>
                                <li>10. CONTACT US</li>
                            </ul>
                            <h3>1. Background</h3>
                            <p>We are a "controller" under the General Data Protection Regulation (GDPR) and other applicable data protection legislation (Data Protection Law). This means we are responsible for deciding how we use the personal data that we collect about you and, in accordance with the Data Protection Law, we will ensure that the personal data we hold about you is, at all times:</p>
                            <ul>
                                <li>1. used fairly, lawfully, and transparently;</li>
                                <li>2. collected for limited, specific purposes only;</li>
                                <li>3. adequate, relevant to and limited to what is necessary for those purposes;</li>
                                <li>4. kept accurate and up-to-date;</li>
                                <li>5. not kept for longer than is necessary; and</li>
                                <li>6. held securely.</li>
                            </ul>
                            <p>We shall be accountable for and able to demonstrate our compliance with our obligations under the Data Protection Law, and this Privacy Policy is one of the ways in which we do that.</p>

                            <h3>2. Personal Data We Collect About You</h3>
                            <p>Personal data means any information about an individual from which that person can be identified. We may collect, store, transfer and use various types of your personal data:</p>
                            <ul>
                                <li><strong>Registration Data:</strong> To access and use some elements of the Services you must create a Know The Game account by providing us with the information which is identified on the relevant registration page: name, display name (optional), email, GAA club (optional) and county (optional). We will also collect a username and password.</li>
                                <li><strong>Contact Data:</strong> When we want to communicate with you, or you with us, we use your email address.</li>
                                <li><strong>User Data:</strong> We collect your usage and preference details related to your use of the Services such as language, game-play statistics, scores, rankings, time spent playing, game profile, preferences, survey responses, feedback and other data that you provide to us as part of your account.</li>
                                <li><strong>Marketing and/or Communications Data:</strong> We retain your preferences in receiving messages from us in relation to other competitions.</li>
                                <li><strong>Technical Data:</strong> Certain data is automatically generated and collected by us when you use the Services such as your IP address, MAC address and other device identifiers; your clickstream to, through and from the Services (including date and time); site pages you viewed or searched for; length of visits to certain site pages; page interaction information (such as scrolling, clicks, and mouse-overs); methods used to browse away from the page.</li>
                            </ul>
                            <p>Not all of the list above will necessarily apply to you - it depends on your use of the Services and your particular interaction and communications with us. Please refer to <strong>4. HOW AND WHY WE USE YOUR PERSONAL DATA</strong> below.</p>

                            <h3>3. How We Collect Your Personal Data</h3>
                            <p>You provide us with your personal data when you:</p>
                            <ul>
                                <li>access, use or play the Services;</li>
                                <li>create a Know The Game account for the Services;</li>
                                <li>request information, marketing and other communications to be sent to you;</li>
                                <li>enter a competition, promotion or survey;</li>
                                <li>complete surveys or provide us with your feedback; and</li>
                                <li>otherwise interact or correspond with us (including via email, social media or telephone).</li>
                            </ul>

                            <h3>4. How and Why We Use Your Personal Data</h3>
                            <p>We will only use your personal data where Data Protection Law allows us to. Data Protection Law says we can collect and use personal data on the following bases:</p>
                            <ul>
                                <li>1. it is necessary for us to be able to perform an agreement with you.</li>
                                <li>2. it is necessary for our legitimate interests (and your interests and fundamental rights do not override those interests);</li>
                                <li>3. if we have your consent (which you can withdraw at any time); or</li>
                                <li>4. to comply with a legal obligation i.e. rules laid down by courts, statute or regulation.</li>
                            </ul>
                            <p>Data Protection Law says we can only collect and use your Sensitive Personal Data where an additional basis applies: for reasons of substantial public interest, including when preventing or detecting unlawful acts or in connection with our regulatory and oversight functions in sport; in connection with legal claims; cases where you have made the data public yourself; or where you have given explicit consent.</p>
                            <p>Accordingly, we lawfully use your personal data in the following ways:</p>
                            <ul>
                                <li><strong>Delivering the Services:</strong> We use the Registration Data, User Data and Contact Data so that we can deliver the Services to you in an effective, efficient and accurate way. Without it, we would not be able to deliver a tailored service to you or respond to issues with these Services that are identified by us or you, or ensure you get the most out of your experience. Therefore, we use this data on the basis that it is necessary for us to be able to perform our agreement with you (i.e. the terms and conditions of the relevant Services) and for our legitimate interests of delivering the Services in this way.</li>
                                <li><strong>Operating the Services:</strong> We use the Technical Data in order to operate and administer the Services including as necessary for testing, analysis, maintenance, support, reporting and hosting of data. Therefore, we use this data on the basis that it is necessary for our legitimate interests of operating the Services in this way. We also use Technical Data together with certain Identity Data and Contact Data to assist in security and fraud prevention, system integrity (such as preventing hacking, cheats and spam) and/or to facilitate our response to a legal process. Therefore, we use this data on the basis that it is necessary both for our legitimate interests in protecting the Services in this way and in order that we can comply with a legal obligation.</li>
                                <li><strong>Competitions and promotions:</strong> We use Identity Data and Contact Data and any other personal data related to the entry (for example, a photograph) in order that we can administer and operate contests, prize draws, competitions or other promotions including selecting the winners, delivering the prizes and publishing the results (as required by EU advertising regulations). Therefore, we use this data on the basis that it is necessary for us to be able to perform our agreement with you (i.e. the terms and conditions of the relevant promotion) and in order that we can comply with a legal obligation. If we want to use the personal data for any other purpose we will notify you and, if necessary, seek your consent at that time.</li>
                                <li><strong>Marketing communications:</strong> We use the Identity Data and Contact Data to inform you of news, offers, events, competitions and promotions by Know The Game.</li>
                            </ul>
                            <p><strong>OPT-ING OUT:</strong> You can withdraw your consent and opt-out of marketing communications from us at any time by updating your Email Preferences (where you have signed up to our website) or by following the instructions provided to you in the relevant communication (for example, the 'unsubscribe' link in an email). Alternatively, you may contact us at <a href="mailto:legal@knowthegame.ie">legal@knowthegame.ie</a>. We may still need to send service emails to you from time to time.</p>
                            <p><strong>Other purposes:</strong> We might have to use your personal data to protect you or someone else's vital interests for example to make contact in rare emergency situations. We could also have to use your personal data in connection with legal and regulatory matters such as our maintenance of business records, compliance with external reporting requirements and internal policies and procedures and responses to requests by government, law enforcement, regulators, courts, rights holders or other third parties including in respect of the use or misuse of intellectual property. Therefore we use this data on the basis that it is necessary both for our legitimate interests in protecting, defending and enforcing rights and interests in this way and also so that we can comply with legal obligations.</p>
                            <p>Before using your personal data for our legitimate interests, we make sure that we take into account any potential impact that such use may have on you to ensure that your interests and fundamental rights and freedoms do not override those interests. In other words, we have determined that we have a legitimate need to process your personal data and we are not aware of any reasons that, on balance, mean we should not be doing so.</p>
                            <p>If you have concerns about our processing please refer to <strong>9. YOUR RIGHTS</strong> below or contact us using the details here <strong>10. CONTACT US.</strong></p>
                            <p>We will only use your personal data for the purposes for which we collected it as described above, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>
                            <p>If you would like to find out more about the legal condition for which we process your personal information, please contact us using the details here <strong>10. CONTACT US.</strong></p>
                            <p><strong>What if you do not want to share your personal data?</strong> Unless otherwise specified above, generally we collect your personal data on a voluntary basis. However, please note that if you decline to provide certain mandatory personal data, you may not be able to access certain Services and we may be unable to fully respond to any inquiries you make.</p>

                            <h3>5. Disclosure of Your Personal Data</h3>
                            <p>We may disclose or share your personal data in the following circumstances:</p>
                            <ul>
                                <li><strong>Third Party Service Providers:</strong> We engage third party businesses to provide services to us or to you on our behalf, such as support for the internal operations of our Services (and related services), communications, data storage and delivering communications (including calendar notifications). Our service providers may access, receive, maintain or otherwise use personal data on our behalf. Our service providers only use your personal data in accordance with our strict instructions to provide the relevant services and are not permitted to use your personal data for their own purposes, unless authorised by us to do so. Where this is the case you will be notified by us and provided with their privacy policies so you can understand how they will treat your personal data.</li>
                                <li><strong>Publicity and media:</strong> We may disclose your personal data publicly via the media, social media or on the Services. For example, if you win a competition or promotion we may disclose your name online. In such cases, we will clearly notify you of the sharing, and you will have the choice not to participate or to otherwise object to such sharing, subject to our other legal obligations.</li>
                                <li><strong>Legally Required:</strong> We may also disclose your personal data if we believe we are required to do so by law, or that doing so is reasonably necessary to comply with legal processes or the enforcement of our rights where we receive evidence we deem sufficient to justify such disclosure.</li>
                            </ul>
                            <p>Please read carefully any additional privacy notices which we may provide to you so that you are aware of and understand any other circumstances in which we may disclose or share your personal data which are specific to your use of the Services.</p>
                            <p>If you would like further information on the specific mechanism used by us when transferring your personal data out of the UK and the EU you can contact us using the details provided below.</p>

                            <h3>6. Security of Your Personal Data</h3>
                            <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed (a Data Security Breach). In addition, we limit access to your personal data to those employees, contractors and other third parties who have a business need to know. They will only use your personal data on our instructions and they are subject to a duty of confidentiality. In relation to third party service providers whom we appoint to process your personal data on our behalf, we take steps to ensure that those service providers are contractually bound to protect your personal data.</p>
                            <p>We have put in place procedures to deal with any suspected Data Security Breach and will notify you and any applicable regulator where we are legally required to do so.</p>
                            <p>Where we have given you or you have chosen a password which enables you to access certain Services, you are responsible for using reasonable care in keeping this password confidential.</p>

                            <h3>7. Links to Other Sites</h3>
                            <p>The Services may contain links to other websites, applications and environments that are not owned or controlled by us (the Other Sites). The owners and operators of those Other Sites are responsible for their collection and/or use of your personal data and you should check their respective privacy policies. Unless specifically referred to otherwise, this Privacy Policy applies to the Services only and not the Other Sites.</p>

                            <h3>8. Data Retention</h3>
                            <p>We will generally only keep your personal data for as long as necessary to fulfil the purposes we collected it for (see 4. WHY WE USE YOUR PERSONAL DATA), in accordance with our internal Data Retention Policy. However, in some circumstances we may retain personal data for other periods of time, for instance where we are required to do so in accordance with legal, tax or accounting requirements, or if required to do so by a legal process, legal authority, or other governmental entity having authority to make the request.</p>
                            <p>To determine the appropriate retention period, we review - in addition to the purposes of use and how we can achieve them - other relevant factors such as the nature and scope of the personal data, the potential risks to data subjects from a Data Security Breach, and the applicable legal requirements, for example the limitation period for which legal claims can be made in court. For example, all non-activated Know The Game accounts are deleted after 21 days.</p>
                            <p>In accordance with our internal Data Retention Policy and the Data Protection Law, after the applicable retention period has ended, your personal data will be securely deleted or destroyed or anonymised (for example, where the data will be used in aggregated/generic form for statistical purposes).</p>
                            <p>Specific details of retention periods for different aspects of your personal data are available upon request by using the contact details provided below.</p>

                            <h3>9. Your Rights</h3>
                            <p>Under Data Protection Law, you have certain rights (depending on the circumstances) in connection with your personal data, which include:</p>
                            <ul>
                                <li><strong>Request access to your personal data:</strong> This enables you to receive a copy of the personal information we hold about you and to check that we are using it lawfully, provided always that this does not adversely affect the rights and freedoms of other people;</li>
                                <li><strong>Request correction of the personal data that we hold about you:</strong> Where any of the information we hold about you is incorrect or incomplete we will act promptly to rectify this, including where you have requested us to do so.</li>
                                <li><strong>Request erasure of your personal data:</strong> This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to use it. You also have the right to ask us to delete or remove your personal data where you have exercised your right to object to our use (see below);</li>
                                <li><strong>Object to use of your personal data:</strong> Where we are relying on our legitimate interests (see above) and there is something about your particular situation which makes you want to object to our use on this ground;</li>
                                <li><strong>Withdraw your consent to our use of your personal data:</strong> Where we do so in reliance on your consent. Once we have received notification that you have withdrawn your consent, we will no longer use your personal data for the purpose or purposes you originally agreed to, unless we have another legitimate basis for doing so in law;</li>
                                <li><strong>Request the restriction of use of your personal data:</strong> This enables you to ask us to suspend the use of personal data about you, for example if you want us to establish its accuracy or the reason for using it; and</li>
                                <li><strong>Request the transfer of the personal data you have provided:</strong> On the basis of consent or for a contract with us, to you or a third party where technically feasible.</li>
                            </ul>
                            <p>We are committed to respecting your rights. You may action your rights (as may be applicable) by contacting us using the details provided below (<strong>10. CONTACT US</strong>). We will make every reasonable effort to comply with your requests within a reasonable period and in any event within the timescales provided by the Data Protection Law, unless we have a lawful reason not to do so. Requests should be made in writing and to ensure that personal data is dealt with carefully and confidentially we will require the requestor to provide verification of their identity and all applications must be accompanied by copies of at least two official documents, which show your name, date of birth and current address (for example, driving licence, birth/adoption certificate, passport, recent utility bill).</p>
                            <p>In responding to such requests, we will explain the impact of any objections, restrictions or deletions requested.</p>
                            <p>We will not charge you a fee to exercise your rights unless your request is clearly unfounded or excessive, in which case we may charge you a reasonable fee. Alternatively, we may refuse to comply with the request in such circumstances.</p>
                            <p>Additionally, should you wish to permanently delete your Knowthegame.com account and your personal data, you may do this yourself by following the below instructions:</p>
                            <ul>
                                <li>Log into your account and navigate to <a href="https://users.knowthegame.com/">https://users.knowthegame.com/</a>;</li>
                                <li>Click 'Manage Profile' on the right hand side.</li>
                                <li>Click 'Delete Account'.</li>
                                <li>You will then be prompted to enter your password. Please then click 'Confirm Password'.</li>
                                <li>On the following page, please click 'Confirm'.</li>
                            </ul>
                            <p>Please note that if you log back in to your account during the 21 day period the account will be reactivated.</p>

                            <h3>10. Contact Us</h3>
                            <p>If you have any questions about this Privacy Policy or how we handle your personal data, please contact us using the following contact details:</p>
                            <p><a href="mailto:legal@knowthegame.ie">legal@knowthegame.ie</a></p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    metaInfo: {
        title: 'Legal'
    },
    data: () => ({
        item: 1,
        items: [
            {text: 'Cookie Disclaimer', icon: 'mdi-devices'},
            {text: 'Terms of use', icon: 'mdi-rocket-launch'},
            {text: 'License', icon: 'mdi-license'}
        ],
        cookiesSections: [
            { "title": "Disclaimer", "value": 'This website uses cookies to enhance your browsing experience and provide personalized content. By using this site, you consent to the use of cookies in accordance with this Cookie Disclaimer.'},
            { "title": "What are cookies?", "value": "Cookies are small text files that are stored on your device when you visit a website. They serve various purposes, including analysing site traffic, remembering your preferences, and enabling certain website features." },
            { "title": "Types of cookies - Essential", "value" : "These cookies are necessary for the website to function properly. They enable basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies." },
            { "title": "Types of cookies - Anayltics", "value" : "We use these cookies to collect information about how visitors use our website, such as the number of visitors, which pages they visit, and how long they stay on the site. This information is aggregated and anonymous and helps us improve the website&#39;s performance and user experience."},
            { "title": "Types of cookies - Advertising", "value" : "These cookies are used to deliver advertisements that are relevant to you and your interests. They may be used by third-party advertisers to track your browsing activity across different websites and tailor ads to your preferences." },
            { "title": "Managing cookies", "value" : "You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer, and you can set most browsers to prevent them from being placed. However, if you do this, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work."},
            { "title": "Changes to disclaimer", "value": " We may update this Cookie Disclaimer from time to time to reflect changes in our practices and services. We encourage you to review this page periodically for any updates."}
        ],
        activeLegalPanel: 0,
        activeLicencePanel: 0
    })
}
</script>